import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, input, computed, signal, runInInjectionContext } from '@angular/core';
import * as i1 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CrmControlValueAccessorDirective, crmControlValueAccessorFactory, crmResolveExpression, crmKillEvent, CrmElementIdPipe } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzCheckboxComponent, NzCheckboxWrapperComponent } from 'ng-zorro-antd/checkbox';
import * as i1$1 from '@angular/common';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { CrmFormDataInputComponent, CrmFormDataWrapperComponent } from 'common-module/form/fragments';
import { toObservable, takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { chunk } from 'lodash-es';
import { NzRowDirective, NzColDirective } from 'ng-zorro-antd/grid';
import { combineLatest, startWith, map } from 'rxjs';
import { debounceTime, takeUntil, map as map$1, take } from 'rxjs/operators';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzAutocompleteComponent, NzAutocompleteTriggerDirective } from 'ng-zorro-antd/auto-complete';
import { NzInputGroupComponent, NzInputDirective, NzTextareaCountComponent } from 'ng-zorro-antd/input';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzInputNumberGroupComponent, NzInputNumberComponent } from 'ng-zorro-antd/input-number';
import { NzRadioGroupComponent, NzRadioComponent } from 'ng-zorro-antd/radio';
import { crmMapOptions } from 'common-module/common';
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
function CrmCheckboxComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, ctx_r0.label), " ");
  }
}
const _forTrack0 = ($index, $item) => $item.value;
function CrmCheckboxGroupComponent_For_3_For_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "label", 4);
    i0.ɵɵlistener("click", function CrmCheckboxGroupComponent_For_3_For_2_Template_label_click_0_listener($event) {
      const option_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.handleOptionClick($event, option_r2));
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    i0.ɵɵproperty("nzValue", option_r2.value)("nzDisabled", option_r2.disabled)("nzChecked", option_r2.checked);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 4, option_r2.label), " ");
  }
}
function CrmCheckboxGroupComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵrepeaterCreate(1, CrmCheckboxGroupComponent_For_3_For_2_Template, 3, 6, "label", 3, _forTrack0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const chunk_r4 = ctx.$implicit;
    i0.ɵɵproperty("nzSpan", chunk_r4.span);
    i0.ɵɵadvance();
    i0.ɵɵrepeater(chunk_r4.options);
  }
}
const _c0 = () => [];
function CrmFormDateComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-date", 1);
  }
  if (rf & 2) {
    let tmp_3_0;
    let tmp_4_0;
    let tmp_5_0;
    let tmp_6_0;
    let tmp_7_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.control)("placeholder", ctx_r0.options.placeholder)("size", (tmp_3_0 = ctx_r0.options.size) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "large")("allowClear", (tmp_4_0 = ctx_r0.options.allowClear) !== null && tmp_4_0 !== undefined ? tmp_4_0 : false)("borderless", (tmp_5_0 = ctx_r0.options.borderless) !== null && tmp_5_0 !== undefined ? tmp_5_0 : false)("format", (tmp_6_0 = ctx_r0.options.format) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "dd/MM/yyyy")("showTime", (tmp_7_0 = ctx_r0.options.showTime) !== null && tmp_7_0 !== undefined ? tmp_7_0 : false);
  }
}
function CrmFormDateComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "date");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = i0.ɵɵpipeBind2(2, 1, ctx_r0.data, (tmp_1_0 = ctx_r0.options.format) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "dd/MM/yyyy")) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "-", " ");
  }
}
function CrmFormInputComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-input", 1);
  }
  if (rf & 2) {
    let tmp_4_0;
    let tmp_5_0;
    let tmp_6_0;
    let tmp_7_0;
    let tmp_8_0;
    let tmp_9_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.control)("prefix", ctx_r0.options.prefix)("suffix", ctx_r0.options.suffix)("placeholder", (tmp_4_0 = ctx_r0.options.placeholder) !== null && tmp_4_0 !== undefined ? tmp_4_0 : ctx_r0.config.label)("compact", (tmp_5_0 = ctx_r0.options.compact) !== null && tmp_5_0 !== undefined ? tmp_5_0 : true)("size", (tmp_6_0 = ctx_r0.options.size) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "large")("borderless", (tmp_7_0 = ctx_r0.options.borderless) !== null && tmp_7_0 !== undefined ? tmp_7_0 : false)("type", (tmp_8_0 = ctx_r0.options.type) !== null && tmp_8_0 !== undefined ? tmp_8_0 : "text")("autocomplete", (tmp_9_0 = ctx_r0.options.autocomplete) !== null && tmp_9_0 !== undefined ? tmp_9_0 : "off")("presets", ctx_r0.options.presets)("addonAfter", ctx_r0.options.addonAfter)("addonBefore", ctx_r0.options.addonBefore);
  }
}
function CrmFormInputComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.data || "-", " ");
  }
}
function CrmFormPasswordComponent_ng_template_4_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵlistener("click", function CrmFormPasswordComponent_ng_template_4_Conditional_0_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.changeType("text"));
    });
    i0.ɵɵelementEnd();
  }
}
function CrmFormPasswordComponent_ng_template_4_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵlistener("click", function CrmFormPasswordComponent_ng_template_4_Conditional_1_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.changeType("password"));
    });
    i0.ɵɵelementEnd();
  }
}
function CrmFormPasswordComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmFormPasswordComponent_ng_template_4_Conditional_0_Template, 1, 0, "span", 3)(1, CrmFormPasswordComponent_ng_template_4_Conditional_1_Template, 1, 0);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, ctx_r1.type === "password" ? 0 : 1);
  }
}
function CrmFormNumberComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-number", 1);
  }
  if (rf & 2) {
    let tmp_5_0;
    let tmp_6_0;
    let tmp_9_0;
    let tmp_10_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.control)("prefix", ctx_r0.options.prefix)("suffix", ctx_r0.options.suffix)("placeholder", ctx_r0.options.placeholder)("compact", (tmp_5_0 = ctx_r0.options.compact) !== null && tmp_5_0 !== undefined ? tmp_5_0 : true)("size", (tmp_6_0 = ctx_r0.options.size) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "large")("min", ctx_r0.options.min)("max", ctx_r0.options.max)("precision", (tmp_9_0 = ctx_r0.options.precision) !== null && tmp_9_0 !== undefined ? tmp_9_0 : 2)("handlerHidden", (tmp_10_0 = ctx_r0.options.handlerHidden) !== null && tmp_10_0 !== undefined ? tmp_10_0 : false)("formatter", ctx_r0.options.formatter)("parser", ctx_r0.options.parser)("addonAfter", ctx_r0.options.addonAfter)("addonBefore", ctx_r0.options.addonBefore)("step", ctx_r0.options.step);
  }
}
function CrmFormNumberComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = ctx_r0.data) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "-", " ");
  }
}
function CrmRadioButtonComponent_For_2_Case_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 1);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 3, option_r1.value, "crm-radio-option"))("nzValue", option_r1.value);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 6, option_r1.label), " ");
  }
}
function CrmRadioButtonComponent_For_2_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 3, option_r1.value, "crm-radio-option"))("nzValue", option_r1.value);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 6, option_r1.label), " ");
  }
}
function CrmRadioButtonComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmRadioButtonComponent_For_2_Case_0_Template, 4, 8)(1, CrmRadioButtonComponent_For_2_Case_1_Template, 4, 8);
  }
  if (rf & 2) {
    let tmp_10_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, (tmp_10_0 = ctx_r1.type) === "radio" ? 0 : tmp_10_0 === "button" ? 1 : -1);
  }
}
const _forTrack1 = ($index, $item) => $item.id;
function CrmSelectComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-option", 1);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    const opt_r1 = ctx.$implicit;
    i0.ɵɵproperty("nzKey", opt_r1.id)("nzValue", opt_r1.value)("nzLabel", i0.ɵɵpipeBind1(1, 3, opt_r1.label));
  }
}
function CrmFormSelectComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-select", 1);
  }
  if (rf & 2) {
    let tmp_2_0;
    let tmp_3_0;
    let tmp_4_0;
    let tmp_5_0;
    let tmp_6_0;
    let tmp_7_0;
    let tmp_8_0;
    let tmp_9_0;
    let tmp_10_0;
    let tmp_12_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.control)("placeholder", (tmp_2_0 = ctx_r0.options.placeholder) !== null && tmp_2_0 !== undefined ? tmp_2_0 : ctx_r0.config.label)("size", (tmp_3_0 = ctx_r0.options.size) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "large")("borderless", (tmp_4_0 = ctx_r0.options.borderless) !== null && tmp_4_0 !== undefined ? tmp_4_0 : false)("options", (tmp_5_0 = ctx_r0.options.options) !== null && tmp_5_0 !== undefined ? tmp_5_0 : i0.ɵɵpureFunction0(12, _c0))("showArrow", (tmp_6_0 = ctx_r0.options.showArrow) !== null && tmp_6_0 !== undefined ? tmp_6_0 : true)("showSearch", (tmp_7_0 = ctx_r0.options.showSearch) !== null && tmp_7_0 !== undefined ? tmp_7_0 : false)("allowClear", (tmp_8_0 = ctx_r0.options.allowClear) !== null && tmp_8_0 !== undefined ? tmp_8_0 : false)("serverSearch", (tmp_9_0 = ctx_r0.options.serverSearch) !== null && tmp_9_0 !== undefined ? tmp_9_0 : false)("dropdownMatchSelectWidth", (tmp_10_0 = ctx_r0.options.dropdownMatchSelectWidth) !== null && tmp_10_0 !== undefined ? tmp_10_0 : false)("search", ctx_r0.options.search)("mode", (tmp_12_0 = ctx_r0.options.mode) !== null && tmp_12_0 !== undefined ? tmp_12_0 : "default");
  }
}
function CrmFormSelectComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.formattedData(), " ");
  }
}
function CrmSwitchComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("crm-switch-label-disabled", ctx_r0.control == null ? null : ctx_r0.control.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 3, ctx_r0.label), " ");
  }
}
function CrmTextareaComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-textarea-count", 0);
    i0.ɵɵelement(1, "textarea", 1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzMaxCharacterCount", ctx_r0.maxCharacterCount);
    i0.ɵɵadvance();
    i0.ɵɵproperty("formControl", ctx_r0.control)("nzStatus", ctx_r0.status())("placeholder", i0.ɵɵpipeBind1(2, 7, ctx_r0.placeholder))("nzSize", ctx_r0.size)("nzBorderless", ctx_r0.borderless)("rows", ctx_r0.rows);
  }
}
function CrmTextareaComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "textarea", 1);
    i0.ɵɵpipe(1, "crmTranslate");
    i0.ɵɵtext(2, "      ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.control)("nzStatus", ctx_r0.status())("placeholder", i0.ɵɵpipeBind1(1, 6, ctx_r0.placeholder))("nzSize", ctx_r0.size)("nzBorderless", ctx_r0.borderless)("rows", ctx_r0.rows);
  }
}
class CrmCheckboxComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.hostClass = 'crm-checkbox';
    this.defaultValue = false;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmCheckboxComponent_BaseFactory;
      return function CrmCheckboxComponent_Factory(t) {
        return (ɵCrmCheckboxComponent_BaseFactory || (ɵCrmCheckboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmCheckboxComponent)))(t || CrmCheckboxComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmCheckboxComponent,
      selectors: [["crm-checkbox"]],
      hostVars: 2,
      hostBindings: function CrmCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        label: "label"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmCheckboxComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 2,
      consts: [["nz-checkbox", "", 3, "formControl"]],
      template: function CrmCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "label", 0);
          i0.ɵɵtemplate(1, CrmCheckboxComponent_Conditional_1_Template, 2, 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("formControl", ctx.control);
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, ctx.label ? 1 : -1);
        }
      },
      dependencies: [CrmTranslatePipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, NzCheckboxComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmCheckboxComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-checkbox',
      template: `
    <label nz-checkbox [formControl]="control">
      @if (label) {
        {{ label | crmTranslate }}
      }
    </label>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmCheckboxComponent)],
      imports: [CrmTranslatePipe, ReactiveFormsModule, NzCheckboxComponent]
    }]
  }], null, {
    label: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormCheckboxComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-checkbox';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormCheckboxComponent_BaseFactory;
      return function CrmFormCheckboxComponent_Factory(t) {
        return (ɵCrmFormCheckboxComponent_BaseFactory || (ɵCrmFormCheckboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormCheckboxComponent)))(t || CrmFormCheckboxComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormCheckboxComponent,
      selectors: [["crm-form-checkbox"]],
      hostVars: 2,
      hostBindings: function CrmFormCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 9,
      consts: [[3, "status", "error", "config"], [3, "formControl", "label"]],
      template: function CrmFormCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵelement(3, "crm-checkbox", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 5, ctx.status$))("error", i0.ɵɵpipeBind1(2, 7, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("formControl", ctx.control)("label", ctx.options == null ? null : ctx.options.label);
        }
      },
      dependencies: [CrmFormDataWrapperComponent, CrmCheckboxComponent, AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormCheckboxComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-checkbox',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      <crm-checkbox
        [formControl]="control"
        [label]="options?.label"
      ></crm-checkbox>
    </crm-form-data-input-wrapper>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmFormDataWrapperComponent, CrmCheckboxComponent, AsyncPipe, ReactiveFormsModule]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns checkbox form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormCheckbox = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormCheckboxComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
const CHECK_ALL_KEY = '__crm_check-all-checkbox-group__';
class CrmCheckboxGroupComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.options = input([]);
    this.columns = input(1);
    this.checkAllAllowed = input(false);
    this.hostClass = 'crm-checkbox-group';
    this.allOptions = computed(() => this.getAllOptions(this.chunkOptions()));
    this.setDisabled = signal(false);
    this.isAllChecked = false;
  }
  ngOnInit() {
    super.ngOnInit();
    runInInjectionContext(this.injector, () => {
      const chunkOptions$ = combineLatest({
        options: crmResolveExpression({
          resolvable: this.options()
        }),
        columns: toObservable(this.columns),
        checkAllAllowed: toObservable(this.checkAllAllowed),
        isDisabled: toObservable(this.setDisabled),
        model: (this.control?.valueChanges.pipe(startWith(this.control.value)) ?? toObservable(this.valueSignal)).pipe(map(value => value ?? []))
      }).pipe(debounceTime(0), map(data => this.calcOptions(data)), takeUntilDestroyed());
      this.chunkOptions = toSignal(chunkOptions$, {
        initialValue: [{
          span: 24,
          options: []
        }]
      });
    });
  }
  setDisabledState(isDisabled) {
    this.setDisabled.set(isDisabled);
  }
  handleOptionClick(event, option) {
    if (option.value === CHECK_ALL_KEY) {
      crmKillEvent(event);
      this.isAllChecked = !this.isAllChecked;
      const values = this.isAllChecked ? this.allOptions().map(({
        value
      }) => value) : [];
      if (this.control) {
        this.control.markAsDirty();
        this.control.setValue(values);
      } else {
        this.change(values);
      }
      return;
    }
    const value = new Set(this.control?.value ?? this.value ?? []);
    if (value.has(option.value)) {
      value.delete(option.value);
    } else {
      value.add(option.value);
    }
    if (this.control) {
      this.control.markAsDirty();
      this.control.setValue(Array.from(value));
    } else {
      this.change(Array.from(value));
    }
  }
  calcOptions({
    columns,
    options,
    isDisabled,
    checkAllAllowed,
    model
  }) {
    const extendedOptions = checkAllAllowed ? [{
      value: CHECK_ALL_KEY,
      label: 'crm.form.checkboxGroup.checkAll'
    }, ...options] : options;
    const mod = extendedOptions.length % columns;
    const perChunk = Math.floor(extendedOptions.length / columns) + mod;
    const chunkOptions = chunk(extendedOptions, perChunk);
    const span = 24 / columns;
    const chunks = chunkOptions.map(chunkOption => ({
      span,
      options: chunkOption.map(option => ({
        ...option,
        checked: model.includes(option.value),
        disabled: (this.control?.disabled || isDisabled) ?? option.disabled
      }))
    }));
    this.isAllChecked = this.getAllOptions(chunks).every(({
      checked
    }) => checked);
    const isAllCheckedOption = chunks[0]?.options?.find(({
      value
    }) => value === CHECK_ALL_KEY);
    if (isAllCheckedOption) {
      isAllCheckedOption.checked = this.isAllChecked;
    }
    return chunks;
  }
  getAllOptions(chunks) {
    return chunks.map(({
      options
    }) => options).flat().filter(({
      value
    }) => value !== CHECK_ALL_KEY);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmCheckboxGroupComponent_BaseFactory;
      return function CrmCheckboxGroupComponent_Factory(t) {
        return (ɵCrmCheckboxGroupComponent_BaseFactory || (ɵCrmCheckboxGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmCheckboxGroupComponent)))(t || CrmCheckboxGroupComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmCheckboxGroupComponent,
      selectors: [["crm-checkbox-group"]],
      hostVars: 2,
      hostBindings: function CrmCheckboxGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: [i0.ɵɵInputFlags.SignalBased, "options"],
        columns: [i0.ɵɵInputFlags.SignalBased, "columns"],
        checkAllAllowed: [i0.ɵɵInputFlags.SignalBased, "checkAllAllowed"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmCheckboxGroupComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 0,
      consts: [[1, "crm-checkbox-group-wrapper"], ["nz-row", ""], ["nz-col", "", 3, "nzSpan"], ["nz-checkbox", "", 1, "crm-checkbox-group-option", 3, "nzValue", "nzDisabled", "nzChecked"], ["nz-checkbox", "", 1, "crm-checkbox-group-option", 3, "click", "nzValue", "nzDisabled", "nzChecked"]],
      template: function CrmCheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-checkbox-wrapper", 0)(1, "div", 1);
          i0.ɵɵrepeaterCreate(2, CrmCheckboxGroupComponent_For_3_Template, 3, 1, "div", 2, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.chunkOptions());
        }
      },
      dependencies: [CrmTranslatePipe, NzCheckboxWrapperComponent, NzCheckboxComponent, NzRowDirective, NzColDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmCheckboxGroupComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-checkbox-group',
      template: `
    <nz-checkbox-wrapper class="crm-checkbox-group-wrapper">
      <div nz-row>
        @for (chunk of chunkOptions(); track idx; let idx = $index) {
          <div nz-col [nzSpan]="chunk.span">
            @for (option of chunk.options; track option.value) {
              <label
                class="crm-checkbox-group-option"
                nz-checkbox
                [nzValue]="option.value"
                [nzDisabled]="option.disabled"
                [nzChecked]="option.checked"
                (click)="handleOptionClick($event, option)"
              >
                {{ option.label | crmTranslate }}
              </label>
            }
          </div>
        }
      </div>
    </nz-checkbox-wrapper>
  `,
      imports: [CrmTranslatePipe, AsyncPipe, NzCheckboxWrapperComponent, NzCheckboxComponent, NzRowDirective, NzColDirective],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmCheckboxGroupComponent)]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormCheckboxGroupComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-checkbox-group';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormCheckboxGroupComponent_BaseFactory;
      return function CrmFormCheckboxGroupComponent_Factory(t) {
        return (ɵCrmFormCheckboxGroupComponent_BaseFactory || (ɵCrmFormCheckboxGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormCheckboxGroupComponent)))(t || CrmFormCheckboxGroupComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormCheckboxGroupComponent,
      selectors: [["crm-form-checkbox-group"]],
      hostVars: 2,
      hostBindings: function CrmFormCheckboxGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 12,
      consts: [[3, "status", "error", "config"], [3, "formControl", "options", "columns", "checkAllAllowed"]],
      template: function CrmFormCheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵelement(3, "crm-checkbox-group", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_4_0;
          let tmp_5_0;
          let tmp_6_0;
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 7, ctx.status$))("error", i0.ɵɵpipeBind1(2, 9, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("formControl", ctx.control)("options", (tmp_4_0 = ctx.options.options) !== null && tmp_4_0 !== undefined ? tmp_4_0 : i0.ɵɵpureFunction0(11, _c0))("columns", (tmp_5_0 = ctx.options.columns) !== null && tmp_5_0 !== undefined ? tmp_5_0 : 1)("checkAllAllowed", (tmp_6_0 = ctx.options.checkAllAllowed) !== null && tmp_6_0 !== undefined ? tmp_6_0 : false);
        }
      },
      dependencies: [CommonModule, i1$1.AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmCheckboxGroupComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormCheckboxGroupComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-checkbox-group',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      <crm-checkbox-group
        [formControl]="control"
        [options]="options.options ?? []"
        [columns]="options.columns ?? 1"
        [checkAllAllowed]="options.checkAllAllowed ?? false"
      ></crm-checkbox-group>
    </crm-form-data-input-wrapper>
  `,
      imports: [CommonModule, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmCheckboxGroupComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns checkbox group form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormCheckboxGroup = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormCheckboxGroupComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmDateComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.size = 'large';
    this.allowClear = false;
    this.borderless = false;
    this.format = 'dd/MM/yyyy';
    this.showTime = false;
    this.hostClass = 'crm-date';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmDateComponent_BaseFactory;
      return function CrmDateComponent_Factory(t) {
        return (ɵCrmDateComponent_BaseFactory || (ɵCrmDateComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmDateComponent)))(t || CrmDateComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmDateComponent,
      selectors: [["crm-date"]],
      hostVars: 2,
      hostBindings: function CrmDateComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        placeholder: "placeholder",
        size: "size",
        allowClear: "allowClear",
        borderless: "borderless",
        format: "format",
        showTime: "showTime"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmDateComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 10,
      consts: [[2, "width", "100%", 3, "nzStatus", "formControl", "nzPlaceHolder", "nzSize", "nzAllowClear", "nzBorderless", "nzFormat", "nzShowTime"]],
      template: function CrmDateComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "nz-date-picker", 0);
          i0.ɵɵpipe(1, "crmTranslate");
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzStatus", ctx.status())("formControl", ctx.control)("nzPlaceHolder", i0.ɵɵpipeBind1(1, 8, ctx.placeholder))("nzSize", ctx.size)("nzAllowClear", ctx.allowClear)("nzBorderless", ctx.borderless)("nzFormat", ctx.format)("nzShowTime", ctx.showTime);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzDatePickerComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDateComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-date',
      template: `
    <nz-date-picker
      style="width: 100%"
      [nzStatus]="status()"
      [formControl]="control"
      [nzPlaceHolder]="placeholder | crmTranslate"
      [nzSize]="size"
      [nzAllowClear]="allowClear"
      [nzBorderless]="borderless"
      [nzFormat]="format"
      [nzShowTime]="showTime"
    ></nz-date-picker>
  `,
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzDatePickerComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmDateComponent)]
    }]
  }], null, {
    placeholder: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    allowClear: [{
      type: Input
    }],
    borderless: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    showTime: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormDateComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-date';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormDateComponent_BaseFactory;
      return function CrmFormDateComponent_Factory(t) {
        return (ɵCrmFormDateComponent_BaseFactory || (ɵCrmFormDateComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormDateComponent)))(t || CrmFormDateComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormDateComponent,
      selectors: [["crm-form-date"]],
      hostVars: 2,
      hostBindings: function CrmFormDateComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 8,
      consts: [[3, "status", "error", "config"], [3, "formControl", "placeholder", "size", "allowClear", "borderless", "format", "showTime"], [1, "crm-form-date__read"]],
      template: function CrmFormDateComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, CrmFormDateComponent_Conditional_3_Template, 1, 7, "crm-date", 1)(4, CrmFormDateComponent_Conditional_4_Template, 3, 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 4, ctx.status$))("error", i0.ɵɵpipeBind1(2, 6, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(3, ctx.mode() === "edit" ? 3 : 4);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmDateComponent, AsyncPipe, DatePipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDateComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-date',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      @if (mode() === 'edit') {
        <crm-date
          [formControl]="control"
          [placeholder]="options.placeholder"
          [size]="options.size ?? 'large'"
          [allowClear]="options.allowClear ?? false"
          [borderless]="options.borderless ?? false"
          [format]="options.format ?? 'dd/MM/yyyy'"
          [showTime]="options.showTime ?? false"
        ></crm-date>
      } @else {
        <div class="crm-form-date__read">
          {{ (data | date: options.format ?? 'dd/MM/yyyy') ?? '-' }}
        </div>
      }
    </crm-form-data-input-wrapper>
  `,
      imports: [ReactiveFormsModule, CrmFormDataWrapperComponent, CrmDateComponent, AsyncPipe, DatePipe],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns date form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormDate = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormDateComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmInputComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.autocomplete = 'off';
    this.borderless = false;
    this.compact = true;
    this.type = 'text';
    this.size = 'large';
    this.hostClass = 'crm-input';
    this.presetsSignal = signal([]);
  }
  ngOnInit() {
    super.ngOnInit();
    crmResolveExpression({
      resolvable: this.presets ?? []
    }).pipe(takeUntil(this.destroyed$)).subscribe(presets => this.presetsSignal.set(presets));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmInputComponent_BaseFactory;
      return function CrmInputComponent_Factory(t) {
        return (ɵCrmInputComponent_BaseFactory || (ɵCrmInputComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmInputComponent)))(t || CrmInputComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmInputComponent,
      selectors: [["crm-input"]],
      hostVars: 2,
      hostBindings: function CrmInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        autocomplete: "autocomplete",
        prefix: "prefix",
        suffix: "suffix",
        addonAfter: "addonAfter",
        addonBefore: "addonBefore",
        placeholder: "placeholder",
        presets: "presets",
        borderless: "borderless",
        compact: "compact",
        type: "type",
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmInputComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 18,
      consts: [["autocompleteRef", ""], [3, "nzPrefix", "nzSuffix", "nzCompact", "nzStatus", "nzSize", "nzAddOnAfter", "nzAddOnBefore"], ["nz-input", "", 3, "autocomplete", "formControl", "nzStatus", "placeholder", "nzSize", "nzBorderless", "type", "nzAutocomplete"], [3, "nzDataSource"]],
      template: function CrmInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-input-group", 1);
          i0.ɵɵelement(1, "input", 2);
          i0.ɵɵpipe(2, "crmTranslate");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "nz-autocomplete", 3, 0);
        }
        if (rf & 2) {
          const autocompleteRef_r1 = i0.ɵɵreference(4);
          i0.ɵɵproperty("nzPrefix", ctx.prefix)("nzSuffix", ctx.suffix)("nzCompact", ctx.compact)("nzStatus", ctx.status())("nzSize", ctx.size)("nzAddOnAfter", ctx.addonAfter)("nzAddOnBefore", ctx.addonBefore);
          i0.ɵɵadvance();
          i0.ɵɵproperty("autocomplete", ctx.autocomplete)("formControl", ctx.control)("nzStatus", ctx.status())("placeholder", i0.ɵɵpipeBind1(2, 16, ctx.placeholder))("nzSize", ctx.size)("nzBorderless", ctx.borderless)("type", ctx.type)("nzAutocomplete", autocompleteRef_r1);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("nzDataSource", ctx.presetsSignal());
        }
      },
      dependencies: [ReactiveFormsModule, i1.DefaultValueAccessor, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzInputGroupComponent, NzInputDirective, NzAutocompleteComponent, NzAutocompleteTriggerDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmInputComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-input',
      template: `
    <nz-input-group
      [nzPrefix]="prefix"
      [nzSuffix]="suffix"
      [nzCompact]="compact"
      [nzStatus]="status()"
      [nzSize]="size"
      [nzAddOnAfter]="addonAfter"
      [nzAddOnBefore]="addonBefore"
    >
      <input
        nz-input
        [autocomplete]="autocomplete"
        [formControl]="control"
        [nzStatus]="status()"
        [placeholder]="placeholder | crmTranslate"
        [nzSize]="size"
        [nzBorderless]="borderless"
        [type]="type"
        [nzAutocomplete]="autocompleteRef"
      />
    </nz-input-group>

    <nz-autocomplete
      [nzDataSource]="presetsSignal()"
      #autocompleteRef
    ></nz-autocomplete>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmInputComponent)],
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzInputGroupComponent, NzInputDirective, NzAutocompleteComponent, NzAutocompleteTriggerDirective]
    }]
  }], null, {
    autocomplete: [{
      type: Input
    }],
    prefix: [{
      type: Input
    }],
    suffix: [{
      type: Input
    }],
    addonAfter: [{
      type: Input
    }],
    addonBefore: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    presets: [{
      type: Input
    }],
    borderless: [{
      type: Input
    }],
    compact: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormInputComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-input';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormInputComponent_BaseFactory;
      return function CrmFormInputComponent_Factory(t) {
        return (ɵCrmFormInputComponent_BaseFactory || (ɵCrmFormInputComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormInputComponent)))(t || CrmFormInputComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormInputComponent,
      selectors: [["crm-form-input"]],
      hostVars: 2,
      hostBindings: function CrmFormInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 8,
      consts: [[3, "status", "error", "config"], [3, "formControl", "prefix", "suffix", "placeholder", "compact", "size", "borderless", "type", "autocomplete", "presets", "addonAfter", "addonBefore"], [1, "crm-form-input__read"]],
      template: function CrmFormInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, CrmFormInputComponent_Conditional_3_Template, 1, 12, "crm-input", 1)(4, CrmFormInputComponent_Conditional_4_Template, 2, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 4, ctx.status$))("error", i0.ɵɵpipeBind1(2, 6, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(3, ctx.mode() === "edit" ? 3 : 4);
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmInputComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormInputComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-input',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      @if (mode() === 'edit') {
        <crm-input
          [formControl]="control"
          [prefix]="options.prefix"
          [suffix]="options.suffix"
          [placeholder]="options.placeholder ?? config.label"
          [compact]="options.compact ?? true"
          [size]="options.size ?? 'large'"
          [borderless]="options.borderless ?? false"
          [type]="options.type ?? 'text'"
          [autocomplete]="options.autocomplete ?? 'off'"
          [presets]="options.presets"
          [addonAfter]="options.addonAfter"
          [addonBefore]="options.addonBefore"
        ></crm-input>
      } @else {
        <div class="crm-form-input__read">
          {{ data || '-' }}
        </div>
      }
    </crm-form-data-input-wrapper>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmInputComponent]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormPasswordComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-password';
    this.type = 'password';
  }
  changeType(type) {
    this.type = type;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormPasswordComponent_BaseFactory;
      return function CrmFormPasswordComponent_Factory(t) {
        return (ɵCrmFormPasswordComponent_BaseFactory || (ɵCrmFormPasswordComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormPasswordComponent)))(t || CrmFormPasswordComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormPasswordComponent,
      selectors: [["crm-form-password"]],
      hostVars: 2,
      hostBindings: function CrmFormPasswordComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 15,
      consts: [["suffix", ""], [3, "status", "error", "config"], [3, "formControl", "suffix", "placeholder", "compact", "size", "borderless", "autocomplete", "type"], ["nz-icon", "", "nzType", "eye", "nzTheme", "outline"], ["nz-icon", "", "nzType", "eye", "nzTheme", "outline", 3, "click"], ["nz-icon", "", "nzType", "eye-invisible", "nzTheme", "outline", 3, "click"]],
      template: function CrmFormPasswordComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 1);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵelement(3, "crm-input", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, CrmFormPasswordComponent_ng_template_4_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          let tmp_6_0;
          let tmp_7_0;
          let tmp_8_0;
          let tmp_9_0;
          let tmp_10_0;
          const suffix_r4 = i0.ɵɵreference(5);
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 11, ctx.status$))("error", i0.ɵɵpipeBind1(2, 13, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("formControl", ctx.control)("suffix", suffix_r4)("placeholder", (tmp_6_0 = ctx.options.placeholder) !== null && tmp_6_0 !== undefined ? tmp_6_0 : ctx.config.label)("compact", (tmp_7_0 = ctx.options.compact) !== null && tmp_7_0 !== undefined ? tmp_7_0 : true)("size", (tmp_8_0 = ctx.options.size) !== null && tmp_8_0 !== undefined ? tmp_8_0 : "large")("borderless", (tmp_9_0 = ctx.options.borderless) !== null && tmp_9_0 !== undefined ? tmp_9_0 : false)("autocomplete", (tmp_10_0 = ctx.options.autocomplete) !== null && tmp_10_0 !== undefined ? tmp_10_0 : "new-password")("type", ctx.type);
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmInputComponent, NzIconDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormPasswordComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-password',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      <crm-input
        [formControl]="control"
        [suffix]="suffix"
        [placeholder]="options.placeholder ?? config.label"
        [compact]="options.compact ?? true"
        [size]="options.size ?? 'large'"
        [borderless]="options.borderless ?? false"
        [autocomplete]="options.autocomplete ?? 'new-password'"
        [type]="type"
      ></crm-input>
    </crm-form-data-input-wrapper>

    <ng-template #suffix>
      @if (type === 'password') {
        <span
          nz-icon
          nzType="eye"
          nzTheme="outline"
          (click)="changeType('text')"
        ></span>
      } @else {
        <span
          nz-icon
          nzType="eye-invisible"
          nzTheme="outline"
          (click)="changeType('password')"
        ></span>
      }
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmInputComponent, NzIconDirective]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns input form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormInput = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormInputComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});

/**
 * Function which returns password form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormPassword = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormPasswordComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmNumberComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.size = 'large';
    this.compact = true;
    this.handlerHidden = false;
    this.precision = 2;
    this.minDefault = Number.MIN_VALUE;
    this.maxDefault = Number.MAX_VALUE;
  }
  ngOnInit() {
    super.ngOnInit();
    this.formatter ??= value => value;
    this.parser ??= value => value;
  }
  onFocus(input) {
    input.onModelChange(input.inputElement.nativeElement.value);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmNumberComponent_BaseFactory;
      return function CrmNumberComponent_Factory(t) {
        return (ɵCrmNumberComponent_BaseFactory || (ɵCrmNumberComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmNumberComponent)))(t || CrmNumberComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmNumberComponent,
      selectors: [["crm-number"]],
      inputs: {
        prefix: "prefix",
        suffix: "suffix",
        addonAfter: "addonAfter",
        addonBefore: "addonBefore",
        placeholder: "placeholder",
        size: "size",
        compact: "compact",
        handlerHidden: "handlerHidden",
        precision: "precision",
        min: "min",
        max: "max",
        step: "step",
        formatter: "formatter",
        parser: "parser"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmNumberComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 20,
      consts: [["input", ""], [2, "width", "100%", 3, "nzPrefix", "nzSuffix", "nzSize", "nzCompact", "nzStatus", "nzAddOnAfter", "nzAddOnBefore"], [2, "width", "100%", 3, "nzFocus", "nzStatus", "formControl", "nzPlaceHolder", "nzMin", "nzMax", "nzStep", "nzPrecision", "nzFormatter", "nzParser"]],
      template: function CrmNumberComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "nz-input-number-group", 1)(1, "nz-input-number", 2, 0);
          i0.ɵɵpipe(3, "crmTranslate");
          i0.ɵɵlistener("nzFocus", function CrmNumberComponent_Template_nz_input_number_nzFocus_1_listener() {
            i0.ɵɵrestoreView(_r1);
            const input_r2 = i0.ɵɵreference(2);
            return i0.ɵɵresetView(ctx.onFocus(input_r2));
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_12_0;
          let tmp_13_0;
          let tmp_14_0;
          i0.ɵɵproperty("nzPrefix", ctx.prefix)("nzSuffix", ctx.suffix)("nzSize", ctx.size)("nzCompact", ctx.compact)("nzStatus", ctx.status())("nzAddOnAfter", ctx.addonAfter)("nzAddOnBefore", ctx.addonBefore);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("crm-number-handler-hidden", ctx.handlerHidden || ctx.suffix);
          i0.ɵɵproperty("nzStatus", ctx.status())("formControl", ctx.control)("nzPlaceHolder", i0.ɵɵpipeBind1(3, 18, ctx.placeholder))("nzMin", (tmp_12_0 = ctx.min) !== null && tmp_12_0 !== undefined ? tmp_12_0 : ctx.minDefault)("nzMax", (tmp_13_0 = ctx.max) !== null && tmp_13_0 !== undefined ? tmp_13_0 : ctx.maxDefault)("nzStep", (tmp_14_0 = ctx.step) !== null && tmp_14_0 !== undefined ? tmp_14_0 : 1)("nzPrecision", ctx.precision)("nzFormatter", ctx.formatter)("nzParser", ctx.parser);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzInputNumberGroupComponent, NzInputNumberComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmNumberComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-number',
      template: `
    <nz-input-number-group
      style="width: 100%"
      [nzPrefix]="prefix"
      [nzSuffix]="suffix"
      [nzSize]="size"
      [nzCompact]="compact"
      [nzStatus]="status()"
      [nzAddOnAfter]="addonAfter"
      [nzAddOnBefore]="addonBefore"
    >
      <nz-input-number
        #input
        style="width: 100%"
        [class.crm-number-handler-hidden]="handlerHidden || suffix"
        [nzStatus]="status()"
        [formControl]="control"
        [nzPlaceHolder]="placeholder | crmTranslate"
        [nzMin]="min ?? minDefault"
        [nzMax]="max ?? maxDefault"
        [nzStep]="step ?? 1"
        [nzPrecision]="precision"
        [nzFormatter]="formatter"
        [nzParser]="parser"
        (nzFocus)="onFocus(input)"
      ></nz-input-number>
    </nz-input-number-group>
  `,
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzInputNumberGroupComponent, NzInputNumberComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmNumberComponent)]
    }]
  }], null, {
    prefix: [{
      type: Input
    }],
    suffix: [{
      type: Input
    }],
    addonAfter: [{
      type: Input
    }],
    addonBefore: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    compact: [{
      type: Input
    }],
    handlerHidden: [{
      type: Input
    }],
    precision: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    formatter: [{
      type: Input
    }],
    parser: [{
      type: Input
    }]
  });
})();
class CrmFormNumberComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-number';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormNumberComponent_BaseFactory;
      return function CrmFormNumberComponent_Factory(t) {
        return (ɵCrmFormNumberComponent_BaseFactory || (ɵCrmFormNumberComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormNumberComponent)))(t || CrmFormNumberComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormNumberComponent,
      selectors: [["crm-form-number"]],
      hostVars: 2,
      hostBindings: function CrmFormNumberComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 8,
      consts: [[3, "status", "error", "config"], [3, "formControl", "prefix", "suffix", "placeholder", "compact", "size", "min", "max", "precision", "handlerHidden", "formatter", "parser", "addonAfter", "addonBefore", "step"], [1, "crm-form-number__read"]],
      template: function CrmFormNumberComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, CrmFormNumberComponent_Conditional_3_Template, 1, 15, "crm-number", 1)(4, CrmFormNumberComponent_Conditional_4_Template, 2, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 4, ctx.status$))("error", i0.ɵɵpipeBind1(2, 6, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(3, ctx.mode() === "edit" ? 3 : 4);
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmNumberComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormNumberComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-number',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      @if (mode() === 'edit') {
        <crm-number
          [formControl]="control"
          [prefix]="options.prefix"
          [suffix]="options.suffix"
          [placeholder]="options.placeholder"
          [compact]="options.compact ?? true"
          [size]="options.size ?? 'large'"
          [min]="options.min"
          [max]="options.max"
          [precision]="options.precision ?? 2"
          [handlerHidden]="options.handlerHidden ?? false"
          [formatter]="$any(options.formatter)"
          [parser]="$any(options.parser)"
          [addonAfter]="options.addonAfter"
          [addonBefore]="options.addonBefore"
          [step]="options.step"
        ></crm-number>
      } @else {
        <div class="crm-form-number__read">
          {{ data ?? '-' }}
        </div>
      }
    </crm-form-data-input-wrapper>
  `,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmNumberComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns number form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormNumber = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormNumberComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmRadioButtonComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.type = 'radio';
    this.buttonStyle = 'solid';
    this.hostClass = 'crm-radio-button';
    this.optionsSignal = signal([]);
  }
  ngOnInit() {
    super.ngOnInit();
    crmResolveExpression({
      resolvable: this.options
    }).pipe(takeUntil(this.destroyed$)).subscribe(options => this.optionsSignal.set(options));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmRadioButtonComponent_BaseFactory;
      return function CrmRadioButtonComponent_Factory(t) {
        return (ɵCrmRadioButtonComponent_BaseFactory || (ɵCrmRadioButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmRadioButtonComponent)))(t || CrmRadioButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmRadioButtonComponent,
      selectors: [["crm-radio-button"]],
      hostVars: 2,
      hostBindings: function CrmRadioButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options",
        type: "type",
        buttonStyle: "buttonStyle"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmRadioButtonComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 2,
      consts: [[1, "crm-form-radio-button-group", 3, "formControl", "nzButtonStyle"], ["nz-radio", "", 1, "crm-form-radio-option", 3, "id", "nzValue"], ["nz-radio-button", "", 1, "crm-form-radio-button-option", 3, "id", "nzValue"]],
      template: function CrmRadioButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-radio-group", 0);
          i0.ɵɵrepeaterCreate(1, CrmRadioButtonComponent_For_2_Template, 2, 1, null, null, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("formControl", ctx.control)("nzButtonStyle", ctx.buttonStyle);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.optionsSignal());
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzRadioGroupComponent, CrmElementIdPipe, NzRadioComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmRadioButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-radio-button',
      template: `
    <nz-radio-group
      class="crm-form-radio-button-group"
      [formControl]="control"
      [nzButtonStyle]="buttonStyle"
    >
      @for (option of optionsSignal(); track option.value) {
        @switch (type) {
          @case ('radio') {
            <label
              class="crm-form-radio-option"
              nz-radio
              [id]="option.value | crmElementId: 'crm-radio-option'"
              [nzValue]="option.value"
            >
              {{ option.label | crmTranslate }}
            </label>
          }

          @case ('button') {
            <label
              class="crm-form-radio-button-option"
              nz-radio-button
              [id]="option.value | crmElementId: 'crm-radio-option'"
              [nzValue]="option.value"
            >
              {{ option.label | crmTranslate }}
            </label>
          }
        }
      }
    </nz-radio-group>
  `,
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzRadioGroupComponent, CrmElementIdPipe, NzRadioComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmRadioButtonComponent)]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    buttonStyle: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormRadioButtonComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-radio-button';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormRadioButtonComponent_BaseFactory;
      return function CrmFormRadioButtonComponent_Factory(t) {
        return (ɵCrmFormRadioButtonComponent_BaseFactory || (ɵCrmFormRadioButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormRadioButtonComponent)))(t || CrmFormRadioButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormRadioButtonComponent,
      selectors: [["crm-form-radio-button"]],
      hostVars: 2,
      hostBindings: function CrmFormRadioButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 12,
      consts: [[3, "status", "error", "config"], [3, "formControl", "options", "buttonStyle", "type"]],
      template: function CrmFormRadioButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵelement(3, "crm-radio-button", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_4_0;
          let tmp_5_0;
          let tmp_6_0;
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 7, ctx.status$))("error", i0.ɵɵpipeBind1(2, 9, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("formControl", ctx.control)("options", (tmp_4_0 = ctx.options.options) !== null && tmp_4_0 !== undefined ? tmp_4_0 : i0.ɵɵpureFunction0(11, _c0))("buttonStyle", (tmp_5_0 = ctx.options.buttonStyle) !== null && tmp_5_0 !== undefined ? tmp_5_0 : "solid")("type", (tmp_6_0 = ctx.options.type) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "radio");
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmRadioButtonComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormRadioButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-radio-button',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      <crm-radio-button
        [formControl]="control"
        [options]="options.options ?? []"
        [buttonStyle]="options.buttonStyle ?? 'solid'"
        [type]="options.type ?? 'radio'"
      ></crm-radio-button>
    </crm-form-data-input-wrapper>
  `,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmRadioButtonComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns radio button form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormRadioButton = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormRadioButtonComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmSelectComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.size = 'large';
    this.borderless = false;
    this.showArrow = true;
    this.showSearch = false;
    this.allowClear = false;
    this.serverSearch = false;
    this.dropdownMatchSelectWidth = false;
    this.mode = 'default';
    this.signalOptions = signal([]);
    this.compareWith = (o1, o2) => {
      if (typeof o1 === 'object' && o1 && 'id' in o1 && typeof o2 === 'object' && o2 && 'id' in o2) {
        return o1['id'] === o2['id'];
      }
      return o1 === o2;
    };
  }
  ngOnInit() {
    super.ngOnInit();
    crmResolveExpression({
      resolvable: this.options ?? []
    }).pipe(map$1(options => crmMapOptions(options)), takeUntil(this.destroyed$)).subscribe(options => this.signalOptions.set(options));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmSelectComponent_BaseFactory;
      return function CrmSelectComponent_Factory(t) {
        return (ɵCrmSelectComponent_BaseFactory || (ɵCrmSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmSelectComponent)))(t || CrmSelectComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmSelectComponent,
      selectors: [["crm-select"]],
      inputs: {
        placeholder: "placeholder",
        size: "size",
        borderless: "borderless",
        showArrow: "showArrow",
        showSearch: "showSearch",
        options: "options",
        allowClear: "allowClear",
        serverSearch: "serverSearch",
        dropdownMatchSelectWidth: "dropdownMatchSelectWidth",
        mode: "mode",
        search: "search"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmSelectComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 14,
      consts: [[2, "width", "100%", 3, "nzOnSearch", "formControl", "nzPlaceHolder", "nzSize", "nzBorderless", "nzShowArrow", "nzAllowClear", "nzStatus", "nzShowSearch", "nzMode", "compareWith", "nzServerSearch", "nzDropdownMatchSelectWidth"], [3, "nzKey", "nzValue", "nzLabel"]],
      template: function CrmSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-select", 0);
          i0.ɵɵpipe(1, "crmTranslate");
          i0.ɵɵlistener("nzOnSearch", function CrmSelectComponent_Template_nz_select_nzOnSearch_0_listener($event) {
            return ctx.search == null ? null : ctx.search($event);
          });
          i0.ɵɵrepeaterCreate(2, CrmSelectComponent_For_3_Template, 2, 5, "nz-option", 1, _forTrack1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("formControl", ctx.control)("nzPlaceHolder", i0.ɵɵpipeBind1(1, 12, ctx.placeholder))("nzSize", ctx.size)("nzBorderless", ctx.borderless)("nzShowArrow", ctx.showArrow)("nzAllowClear", ctx.allowClear)("nzStatus", ctx.status())("nzShowSearch", ctx.showSearch)("nzMode", ctx.mode)("compareWith", ctx.compareWith)("nzServerSearch", ctx.serverSearch)("nzDropdownMatchSelectWidth", ctx.dropdownMatchSelectWidth);
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.signalOptions());
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzSelectComponent, NzOptionComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmSelectComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-select',
      template: `
    <nz-select
      style="width: 100%"
      [formControl]="control"
      [nzPlaceHolder]="placeholder | crmTranslate"
      [nzSize]="size"
      [nzBorderless]="borderless"
      [nzShowArrow]="showArrow"
      [nzAllowClear]="allowClear"
      [nzStatus]="status()"
      [nzShowSearch]="showSearch"
      [nzMode]="mode"
      [compareWith]="compareWith"
      [nzServerSearch]="serverSearch"
      [nzDropdownMatchSelectWidth]="dropdownMatchSelectWidth"
      (nzOnSearch)="search?.($event)"
    >
      @for (opt of signalOptions(); track opt.id) {
        <nz-option
          [nzKey]="opt.id"
          [nzValue]="opt.value"
          [nzLabel]="opt.label | crmTranslate"
        ></nz-option>
      }
    </nz-select>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmSelectComponent)],
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzSelectComponent, NzOptionComponent]
    }]
  }], null, {
    placeholder: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    borderless: [{
      type: Input
    }],
    showArrow: [{
      type: Input
    }],
    showSearch: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    allowClear: [{
      type: Input
    }],
    serverSearch: [{
      type: Input
    }],
    dropdownMatchSelectWidth: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    search: [{
      type: Input
    }]
  });
})();
class CrmFormSelectComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-select';
    this.formattedData = signal('-');
  }
  onReadMode() {
    super.onReadMode();
    crmResolveExpression({
      resolvable: this.options.dataFormatter ?? this.data,
      source: this.data
    }).pipe(take(1)).subscribe(data => this.formattedData.set(data ?? '-'));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormSelectComponent_BaseFactory;
      return function CrmFormSelectComponent_Factory(t) {
        return (ɵCrmFormSelectComponent_BaseFactory || (ɵCrmFormSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormSelectComponent)))(t || CrmFormSelectComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormSelectComponent,
      selectors: [["crm-form-select"]],
      hostVars: 2,
      hostBindings: function CrmFormSelectComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 8,
      consts: [[3, "status", "error", "config"], [3, "formControl", "placeholder", "size", "borderless", "options", "showArrow", "showSearch", "allowClear", "serverSearch", "dropdownMatchSelectWidth", "search", "mode"], [1, "crm-form-select__read"]],
      template: function CrmFormSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, CrmFormSelectComponent_Conditional_3_Template, 1, 13, "crm-select", 1)(4, CrmFormSelectComponent_Conditional_4_Template, 2, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 4, ctx.status$))("error", i0.ɵɵpipeBind1(2, 6, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(3, ctx.mode() === "edit" ? 3 : 4);
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmSelectComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormSelectComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-select',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      @if (mode() === 'edit') {
        <crm-select
          [formControl]="control"
          [placeholder]="options.placeholder ?? config.label"
          [size]="options.size ?? 'large'"
          [borderless]="options.borderless ?? false"
          [options]="options.options ?? []"
          [showArrow]="options.showArrow ?? true"
          [showSearch]="options.showSearch ?? false"
          [allowClear]="options.allowClear ?? false"
          [serverSearch]="options.serverSearch ?? false"
          [dropdownMatchSelectWidth]="options.dropdownMatchSelectWidth ?? false"
          [search]="options.search"
          [mode]="options.mode ?? 'default'"
        ></crm-select>
      } @else {
        <div class="crm-form-select__read">
          {{ formattedData() }}
        </div>
      }
    </crm-form-data-input-wrapper>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmSelectComponent]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns select form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormSelect = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormSelectComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmSwitchComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.size = 'default';
    this.hostClass = 'crm-switch';
    this.defaultValue = false;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmSwitchComponent_BaseFactory;
      return function CrmSwitchComponent_Factory(t) {
        return (ɵCrmSwitchComponent_BaseFactory || (ɵCrmSwitchComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmSwitchComponent)))(t || CrmSwitchComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmSwitchComponent,
      selectors: [["crm-switch"]],
      hostVars: 2,
      hostBindings: function CrmSwitchComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        label: "label",
        checkedChildren: "checkedChildren",
        unCheckedChildren: "unCheckedChildren",
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmSwitchComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 5,
      consts: [[1, "crm-switch-inner", 3, "nzSize", "formControl", "nzCheckedChildren", "nzUnCheckedChildren"], [1, "crm-switch-label", 3, "crm-switch-label-disabled"], [1, "crm-switch-label"]],
      template: function CrmSwitchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "nz-switch", 0);
          i0.ɵɵtemplate(1, CrmSwitchComponent_Conditional_1_Template, 3, 5, "span", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzSize", ctx.size)("formControl", ctx.control)("nzCheckedChildren", ctx.checkedChildren)("nzUnCheckedChildren", ctx.unCheckedChildren);
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, ctx.label ? 1 : -1);
        }
      },
      dependencies: [ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzSwitchComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmSwitchComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-switch',
      template: `
    <nz-switch
      class="crm-switch-inner"
      [nzSize]="size"
      [formControl]="control"
      [nzCheckedChildren]="checkedChildren"
      [nzUnCheckedChildren]="unCheckedChildren"
    ></nz-switch>

    @if (label) {
      <span
        class="crm-switch-label"
        [class.crm-switch-label-disabled]="control?.disabled"
      >
        {{ label | crmTranslate }}
      </span>
    }
  `,
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzSwitchComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmSwitchComponent)]
    }]
  }], null, {
    label: [{
      type: Input
    }],
    checkedChildren: [{
      type: Input
    }],
    unCheckedChildren: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormSwitchComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-switch';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormSwitchComponent_BaseFactory;
      return function CrmFormSwitchComponent_Factory(t) {
        return (ɵCrmFormSwitchComponent_BaseFactory || (ɵCrmFormSwitchComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormSwitchComponent)))(t || CrmFormSwitchComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormSwitchComponent,
      selectors: [["crm-form-switch"]],
      hostVars: 2,
      hostBindings: function CrmFormSwitchComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 12,
      consts: [[3, "status", "error", "config"], [3, "formControl", "label", "size", "checkedChildren", "unCheckedChildren"]],
      template: function CrmFormSwitchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵelement(3, "crm-switch", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_5_0;
          let tmp_6_0;
          let tmp_7_0;
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 8, ctx.status$))("error", i0.ɵɵpipeBind1(2, 10, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("formControl", ctx.control)("label", ctx.options.label)("size", (tmp_5_0 = ctx.options.size) !== null && tmp_5_0 !== undefined ? tmp_5_0 : "default")("checkedChildren", (tmp_6_0 = ctx.options.checkedChildren) !== null && tmp_6_0 !== undefined ? tmp_6_0 : null)("unCheckedChildren", (tmp_7_0 = ctx.options.unCheckedChildren) !== null && tmp_7_0 !== undefined ? tmp_7_0 : null);
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmSwitchComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormSwitchComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-switch',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      <crm-switch
        [formControl]="control"
        [label]="options.label"
        [size]="options.size ?? 'default'"
        [checkedChildren]="options.checkedChildren ?? null"
        [unCheckedChildren]="options.unCheckedChildren ?? null"
      ></crm-switch>
    </crm-form-data-input-wrapper>
  `,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmSwitchComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns switch form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormSwitch = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormSwitchComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
class CrmTextareaComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.borderless = false;
    this.compact = true;
    this.type = 'text';
    this.size = 'large';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmTextareaComponent_BaseFactory;
      return function CrmTextareaComponent_Factory(t) {
        return (ɵCrmTextareaComponent_BaseFactory || (ɵCrmTextareaComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmTextareaComponent)))(t || CrmTextareaComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmTextareaComponent,
      selectors: [["crm-textarea"]],
      inputs: {
        placeholder: "placeholder",
        borderless: "borderless",
        compact: "compact",
        type: "type",
        size: "size",
        rows: "rows",
        maxCharacterCount: "maxCharacterCount"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmTextareaComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 1,
      consts: [[3, "nzMaxCharacterCount"], ["nz-input", "", 3, "formControl", "nzStatus", "placeholder", "nzSize", "nzBorderless", "rows"]],
      template: function CrmTextareaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmTextareaComponent_Conditional_0_Template, 3, 9, "nz-textarea-count", 0)(1, CrmTextareaComponent_Conditional_1_Template, 3, 8);
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.maxCharacterCount ? 0 : 1);
        }
      },
      dependencies: [ReactiveFormsModule, i1.DefaultValueAccessor, i1.NgControlStatus, i1.FormControlDirective, CrmTranslatePipe, NzTextareaCountComponent, NzInputDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmTextareaComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-textarea',
      /**
       * duplicate textarea is not an optimal solution, but unfortunately using template outlet
       * is currently not an option. See more: https://github.com/NG-ZORRO/ng-zorro-antd/issues/5179
       */
      template: `
    @if (maxCharacterCount) {
      <nz-textarea-count [nzMaxCharacterCount]="maxCharacterCount">
        <textarea
          nz-input
          [formControl]="control"
          [nzStatus]="status()"
          [placeholder]="placeholder | crmTranslate"
          [nzSize]="size"
          [nzBorderless]="borderless"
          [rows]="rows"
        ></textarea>
      </nz-textarea-count>
    } @else {
      <textarea
        nz-input
        [formControl]="control"
        [nzStatus]="status()"
        [placeholder]="placeholder | crmTranslate"
        [nzSize]="size"
        [nzBorderless]="borderless"
        [rows]="rows"
      >
      </textarea>
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [crmControlValueAccessorFactory(CrmTextareaComponent)],
      imports: [ReactiveFormsModule, CrmTranslatePipe, NzTextareaCountComponent, NzInputDirective]
    }]
  }], null, {
    placeholder: [{
      type: Input
    }],
    borderless: [{
      type: Input
    }],
    compact: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    rows: [{
      type: Input
    }],
    maxCharacterCount: [{
      type: Input
    }]
  });
})();
class CrmFormTextareaComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
    this.hostClass = 'crm-form-text';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormTextareaComponent_BaseFactory;
      return function CrmFormTextareaComponent_Factory(t) {
        return (ɵCrmFormTextareaComponent_BaseFactory || (ɵCrmFormTextareaComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormTextareaComponent)))(t || CrmFormTextareaComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormTextareaComponent,
      selectors: [["crm-form-text"]],
      hostVars: 2,
      hostBindings: function CrmFormTextareaComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 15,
      consts: [[3, "status", "error", "config"], [3, "formControl", "placeholder", "compact", "size", "borderless", "type", "rows", "maxCharacterCount"]],
      template: function CrmFormTextareaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-form-data-input-wrapper", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵpipe(2, "async");
          i0.ɵɵelement(3, "crm-textarea", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_4_0;
          let tmp_5_0;
          let tmp_6_0;
          let tmp_7_0;
          let tmp_8_0;
          let tmp_10_0;
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(1, 11, ctx.status$))("error", i0.ɵɵpipeBind1(2, 13, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("formControl", ctx.control)("placeholder", (tmp_4_0 = ctx.options.placeholder) !== null && tmp_4_0 !== undefined ? tmp_4_0 : ctx.config.label)("compact", (tmp_5_0 = ctx.options.compact) !== null && tmp_5_0 !== undefined ? tmp_5_0 : true)("size", (tmp_6_0 = ctx.options.size) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "large")("borderless", (tmp_7_0 = ctx.options.borderless) !== null && tmp_7_0 !== undefined ? tmp_7_0 : false)("type", (tmp_8_0 = ctx.options.type) !== null && tmp_8_0 !== undefined ? tmp_8_0 : "text")("rows", ctx.options.rows)("maxCharacterCount", (tmp_10_0 = ctx.options.maxCharacterCount) !== null && tmp_10_0 !== undefined ? tmp_10_0 : undefined);
        }
      },
      dependencies: [AsyncPipe, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, CrmFormDataWrapperComponent, CrmTextareaComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormTextareaComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-text',
      template: `
    <crm-form-data-input-wrapper
      [status]="status$ | async"
      [error]="error$ | async"
      [config]="config"
    >
      <crm-textarea
        [formControl]="control"
        [placeholder]="options.placeholder ?? config.label"
        [compact]="options.compact ?? true"
        [size]="options.size ?? 'large'"
        [borderless]="options.borderless ?? false"
        [type]="options.type ?? 'text'"
        [rows]="options.rows"
        [maxCharacterCount]="options.maxCharacterCount ?? undefined"
      ></crm-textarea>
    </crm-form-data-input-wrapper>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [AsyncPipe, ReactiveFormsModule, CrmFormDataWrapperComponent, CrmTextareaComponent]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns textarea form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormTextarea = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormTextareaComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});

/**
 * Generated bundle index. Do not edit.
 */

export { CrmCheckboxComponent, CrmCheckboxGroupComponent, CrmDateComponent, CrmFormCheckboxComponent, CrmFormCheckboxGroupComponent, CrmFormDateComponent, CrmFormInputComponent, CrmFormNumberComponent, CrmFormPasswordComponent, CrmFormRadioButtonComponent, CrmFormSelectComponent, CrmFormSwitchComponent, CrmFormTextareaComponent, CrmInputComponent, CrmNumberComponent, CrmRadioButtonComponent, CrmSelectComponent, CrmSwitchComponent, CrmTextareaComponent, crmGetFormCheckbox, crmGetFormCheckboxGroup, crmGetFormDate, crmGetFormInput, crmGetFormNumber, crmGetFormPassword, crmGetFormRadioButton, crmGetFormSelect, crmGetFormSwitch, crmGetFormTextarea };
